import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useLoading } from 'utils/loading';
import { useAPI } from 'utils/api';
import _ from 'lodash';
import { isAuthenticated, updateAuth, getAuth } from 'utils/_auth.js';
import { PATH_HOME } from 'utils/constants/paths.js';

const CookieRefresh = ({ location }) => {
  const api = useAPI();
  const loading = useLoading();

  const getCcAuth = (onFinished) => {
    loading.show();
    api
      .getCookie()
      .then((res) => {
        loading.dismiss();
        console.log('getCookie res', res);
        updateAuth({ ccAuth: _.get(res, 'cookie', '') });
        const { ccAuth } = getAuth();
        api.updateToken({ ccAuth });
        if (onFinished) {
          onFinished();
        }
      })
      .catch((error) => {
        loading.dismiss();

        console.log('cookie exception =', error);
      });
  };

  useEffect(() => {
    if (isAuthenticated()) {
      getCcAuth(() => {
        if (location.state && location.state.prevPath) {
          navigate(location.state.prevPath, { replace: true });
        } else {
          navigate(PATH_HOME, { replace: true });
        }
      });
    } else {
      navigate(PATH_HOME, { replace: true });
    }
  }, []);

  return <div></div>;
};

export default CookieRefresh;
